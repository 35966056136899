import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import Loadable from 'react-loadable';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import { isEmpty } from 'lodash';
import WatchFilterSlider from '../components/Carousel/WatchFilterSlider';
import PodCastListsWithCategories from '../components/podcast/podCastListsWithCategories';
import PodcastExternalLinks from '../components/PodcastExternalLinks/PodcastExternalLinks';

const loader = () => <></>;

const LoadableBannerCarousel = Loadable({
  loader: () => import('../components/podcast/PodCastTopSlider'),
  loading: loader,
});

const normalizePodCastData = (podcasts) => {
  const categorizedPodcasts = {};

  podcasts.map((podcast) => {
    console.log(podcast.data.podcastCategories)
    podcast.data.podcastCategories.map((category) => {
      // console.log("cat", category)
      const categoryId = category.podcast_categories.uid  ;
      if (!categorizedPodcasts[categoryId]) {
        categorizedPodcasts[categoryId] = [];
      }
      categorizedPodcasts[categoryId].push(podcast);
    });
  });

  return categorizedPodcasts;
};

const PodCast = ({ data }) => {
  const { podCastData, bgPodCast, podCastCategoriesWithPriorityOrder } = data;
  const [selectedCat, setSelectedCat] = useState('all');

  const featuredEpisodes = podCastData.nodes.slice(0, 3);
  
  // Normalize podcast data to group by categories
  const categorizedPodcasts = normalizePodCastData(podCastData.nodes);

  // Sort categories based on priority
  const sortedCategories = podCastCategoriesWithPriorityOrder.nodes.map((category) => ({
    uid: category.uid,
    data: categorizedPodcasts[category.uid] || [],
  }));

  const seoData = {
    title: 'Listen to All Podcasts Here',
    metaDesc: 'Discover our diverse collection of podcasts, including interviews, documentaries, and more.',
    opengraphImage: {
      sourceUrl: bgPodCast?.childImageSharp?.fluid?.src || '',
    },
  };
  const sCat = sortedCategories[0]

  return (
    <BodyClassName className="body-light page-watch podcast">
      <DefaultLayoutComponent seo={seoData} title="Listen to All Podcasts Here">
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner bgImage bg-pos-center-top"
            fluid={bgPodCast.childImageSharp.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-lg pd-x-sm la-pn top-slider">
              <div className="carousel pd-x-sm la-pn slideshow-container">
                <LoadableBannerCarousel podCast={featuredEpisodes} />
              </div>
            </div>
          </BackgroundImage>

          {/* Category Tabs */}
          <section className="categories-watch categories-filters bg-dark">
            <div className="wrapper wrapper-lg pd-x-md">
              <div className="filter-categories pd-x-sm">
                <WatchFilterSlider
                 categories={sortedCategories.map((item) => item.data && item.uid)}

                  onCatSelected={setSelectedCat}
                  selectedCat={selectedCat}
                />
                {/* <WatchFilterSlider
                  categories={normalizedPodCastData.map((item) => item.data && item.podcastCategories)}
                  onCatSelected={setSelectedCat}
                  selectedCat={selectedCat}
                /> */}
              </div>

              {/* Podcast List Based on Selected Category */}
              <div className="cat_watch-list">
                {!isEmpty(sortedCategories) &&
                  sortedCategories
                    .filter((category) => selectedCat === 'all' || selectedCat === category.uid)
                    .map((category, index) => (
                      <PodCastListsWithCategories
                        key={`${index}-${category.uid}`}
                        title={category.uid}
                        data={category.data}
                        showCategory={true}
                      />
                    ))}
              </div>
            </div>
          </section>

          <PodcastExternalLinks />
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export default PodCast;

export const query = graphql`
  query PodCastPage {
    bgPodCast: file(relativePath: { eq: "podCastBg.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    podCastData: allPrismicPodcast(sort: { fields: data___priority, order: ASC }) {
      nodes {
        data {
          thumnailImage: thumbnail_image {
            url
          }
          podCastTitle: podcast_title {
            text
          }
          podcastCategories: podcast_categories1 {
            podcast_categories {
              uid
            }
          }
          podcastDuration: podcast_duration
          priority
        }
        uid
      }
    }
    podCastCategoriesWithPriorityOrder: allPrismicAudioCategory(sort: { order: ASC, fields: data___priority }) {
      nodes {
        uid
        data {
          priority
        }
      }
    }
  }
`;
